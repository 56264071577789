import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _605d06e8 = () => interopDefault(import('../pages/appointment/index.vue' /* webpackChunkName: "pages/appointment/index" */))
const _1ffec302 = () => interopDefault(import('../pages/article/index.vue' /* webpackChunkName: "pages/article/index" */))
const _fb757d6a = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _a3eb88ea = () => interopDefault(import('../pages/glossary/index.vue' /* webpackChunkName: "pages/glossary/index" */))
const _53b1c8f4 = () => interopDefault(import('../pages/home-service/index.vue' /* webpackChunkName: "pages/home-service/index" */))
const _32be8142 = () => interopDefault(import('../pages/kit/index.vue' /* webpackChunkName: "pages/kit/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _25f57c77 = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _076ad200 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _91fa2352 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _49788fd1 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _e99ef7e6 = () => interopDefault(import('../pages/partnership/index.vue' /* webpackChunkName: "pages/partnership/index" */))
const _7071166c = () => interopDefault(import('../pages/promotion/index.vue' /* webpackChunkName: "pages/promotion/index" */))
const _355298b6 = () => interopDefault(import('../pages/reswab/index.vue' /* webpackChunkName: "pages/reswab/index" */))
const _27b31a95 = () => interopDefault(import('../pages/sales/index.vue' /* webpackChunkName: "pages/sales/index" */))
const _a75f114e = () => interopDefault(import('../pages/setting/index.vue' /* webpackChunkName: "pages/setting/index" */))
const _152321f8 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _b4bf1370 = () => interopDefault(import('../pages/verification/index.vue' /* webpackChunkName: "pages/verification/index" */))
const _3449c657 = () => interopDefault(import('../pages/360/acl.ts' /* webpackChunkName: "pages/360/acl" */))
const _09ef0ee6 = () => interopDefault(import('../pages/360/category/index.vue' /* webpackChunkName: "pages/360/category/index" */))
const _8035a890 = () => interopDefault(import('../pages/360/interactome/index.vue' /* webpackChunkName: "pages/360/interactome/index" */))
const _2e5cef10 = () => interopDefault(import('../pages/360/mapper/index.vue' /* webpackChunkName: "pages/360/mapper/index" */))
const _ac7fb2aa = () => interopDefault(import('../pages/360/panel/index.vue' /* webpackChunkName: "pages/360/panel/index" */))
const _8954fc8e = () => interopDefault(import('../pages/360/score/index.vue' /* webpackChunkName: "pages/360/score/index" */))
const _7d74a73a = () => interopDefault(import('../pages/360/sub-category/index.vue' /* webpackChunkName: "pages/360/sub-category/index" */))
const _d0b51818 = () => interopDefault(import('../pages/360/traits/index.vue' /* webpackChunkName: "pages/360/traits/index" */))
const _847b1f7c = () => interopDefault(import('../pages/analytic/tracking/index.vue' /* webpackChunkName: "pages/analytic/tracking/index" */))
const _700cbaa6 = () => interopDefault(import('../pages/appointment/consultation/index.vue' /* webpackChunkName: "pages/appointment/consultation/index" */))
const _5686bef1 = () => interopDefault(import('../pages/appointment/counselor/index.vue' /* webpackChunkName: "pages/appointment/counselor/index" */))
const _37bc39a2 = () => interopDefault(import('../pages/appointment/feedback/index.vue' /* webpackChunkName: "pages/appointment/feedback/index" */))
const _7c40d7e9 = () => interopDefault(import('../pages/appointment/setting/index.vue' /* webpackChunkName: "pages/appointment/setting/index" */))
const _523397de = () => interopDefault(import('../pages/article/add/index.vue' /* webpackChunkName: "pages/article/add/index" */))
const _05f84a24 = () => interopDefault(import('../pages/article/category/index.vue' /* webpackChunkName: "pages/article/category/index" */))
const _54e599b0 = () => interopDefault(import('../pages/article/edit/index.vue' /* webpackChunkName: "pages/article/edit/index" */))
const _0263d551 = () => interopDefault(import('../pages/article/sub-category/index.vue' /* webpackChunkName: "pages/article/sub-category/index" */))
const _08a26820 = () => interopDefault(import('../pages/asaxperience/merchant/index.vue' /* webpackChunkName: "pages/asaxperience/merchant/index" */))
const _baf84582 = () => interopDefault(import('../pages/customer/view/index.vue' /* webpackChunkName: "pages/customer/view/index" */))
const _0c69d452 = () => interopDefault(import('../pages/dna-report/update/index.vue' /* webpackChunkName: "pages/dna-report/update/index" */))
const _f45c71d8 = () => interopDefault(import('../pages/dna/pdf-report/index.vue' /* webpackChunkName: "pages/dna/pdf-report/index" */))
const _45acf3f0 = () => interopDefault(import('../pages/ehr/mcu/index.vue' /* webpackChunkName: "pages/ehr/mcu/index" */))
const _833baf98 = () => interopDefault(import('../pages/home-service/settings/index.vue' /* webpackChunkName: "pages/home-service/settings/index" */))
const _7466e4e4 = () => interopDefault(import('../pages/kit/acl.ts' /* webpackChunkName: "pages/kit/acl" */))
const _9578a6c8 = () => interopDefault(import('../pages/kit/activation/index.vue' /* webpackChunkName: "pages/kit/activation/index" */))
const _f15e61ec = () => interopDefault(import('../pages/kit/batch/index.vue' /* webpackChunkName: "pages/kit/batch/index" */))
const _386fe8aa = () => interopDefault(import('../pages/kit/view/index.vue' /* webpackChunkName: "pages/kit/view/index" */))
const _57688dc3 = () => interopDefault(import('../pages/media/music/index.vue' /* webpackChunkName: "pages/media/music/index" */))
const _77063322 = () => interopDefault(import('../pages/media/podcast/index.vue' /* webpackChunkName: "pages/media/podcast/index" */))
const _448e0412 = () => interopDefault(import('../pages/news/add/index.vue' /* webpackChunkName: "pages/news/add/index" */))
const _c7906c62 = () => interopDefault(import('../pages/news/edit/index.vue' /* webpackChunkName: "pages/news/edit/index" */))
const _ef37bbfe = () => interopDefault(import('../pages/nutrigenetic/category/index.vue' /* webpackChunkName: "pages/nutrigenetic/category/index" */))
const _965d7f7a = () => interopDefault(import('../pages/nutrigenetic/config.ts' /* webpackChunkName: "pages/nutrigenetic/config" */))
const _15bc6584 = () => interopDefault(import('../pages/nutrigenetic/mapper/index.vue' /* webpackChunkName: "pages/nutrigenetic/mapper/index" */))
const _4e1e11b7 = () => interopDefault(import('../pages/nutrigenetic/panel/index.vue' /* webpackChunkName: "pages/nutrigenetic/panel/index" */))
const _88faf5a6 = () => interopDefault(import('../pages/nutrigenetic/report-mapper/index.vue' /* webpackChunkName: "pages/nutrigenetic/report-mapper/index" */))
const _5fb36cc5 = () => interopDefault(import('../pages/nutrigenetic/score/index.vue' /* webpackChunkName: "pages/nutrigenetic/score/index" */))
const _393676ae = () => interopDefault(import('../pages/nutrigenetic/sub-category/index.vue' /* webpackChunkName: "pages/nutrigenetic/sub-category/index" */))
const _7f04ea68 = () => interopDefault(import('../pages/nutrigenetic/traits/index.vue' /* webpackChunkName: "pages/nutrigenetic/traits/index" */))
const _34fda73a = () => interopDefault(import('../pages/partner/add/index.vue' /* webpackChunkName: "pages/partner/add/index" */))
const _da15a560 = () => interopDefault(import('../pages/partner/bank/index.vue' /* webpackChunkName: "pages/partner/bank/index" */))
const _5393a910 = () => interopDefault(import('../pages/partner/order/index.vue' /* webpackChunkName: "pages/partner/order/index" */))
const _2f3ca70a = () => interopDefault(import('../pages/partner/role/index.vue' /* webpackChunkName: "pages/partner/role/index" */))
const _61dab279 = () => interopDefault(import('../pages/partner/view/index.vue' /* webpackChunkName: "pages/partner/view/index" */))
const _79029814 = () => interopDefault(import('../pages/prodigy/category/index.vue' /* webpackChunkName: "pages/prodigy/category/index" */))
const _6c5a7d5e = () => interopDefault(import('../pages/prodigy/localization/index.vue' /* webpackChunkName: "pages/prodigy/localization/index" */))
const _52ccf0b9 = () => interopDefault(import('../pages/prodigy/mapper/index.vue' /* webpackChunkName: "pages/prodigy/mapper/index" */))
const _ec36683c = () => interopDefault(import('../pages/prodigy/panel/index.vue' /* webpackChunkName: "pages/prodigy/panel/index" */))
const _c90bb220 = () => interopDefault(import('../pages/prodigy/score/index.vue' /* webpackChunkName: "pages/prodigy/score/index" */))
const _87d514c6 = () => interopDefault(import('../pages/prodigy/traits/index.vue' /* webpackChunkName: "pages/prodigy/traits/index" */))
const _59366d8b = () => interopDefault(import('../pages/product/category/index.vue' /* webpackChunkName: "pages/product/category/index" */))
const _41984ab3 = () => interopDefault(import('../pages/product/product-data.ts' /* webpackChunkName: "pages/product/product-data" */))
const _6790cdce = () => interopDefault(import('../pages/product/purchase/index.vue' /* webpackChunkName: "pages/product/purchase/index" */))
const _e11a627e = () => interopDefault(import('../pages/product/report/index.vue' /* webpackChunkName: "pages/product/report/index" */))
const _2946edc7 = () => interopDefault(import('../pages/product/type/index.vue' /* webpackChunkName: "pages/product/type/index" */))
const _504e4505 = () => interopDefault(import('../pages/promotion/banner/index.vue' /* webpackChunkName: "pages/promotion/banner/index" */))
const _6fefe758 = () => interopDefault(import('../pages/promotion/bundling/index.vue' /* webpackChunkName: "pages/promotion/bundling/index" */))
const _2dfcb0b6 = () => interopDefault(import('../pages/promotion/referral/index.vue' /* webpackChunkName: "pages/promotion/referral/index" */))
const _07fb736a = () => interopDefault(import('../pages/promotion/voucher/index.vue' /* webpackChunkName: "pages/promotion/voucher/index" */))
const _b27e88c6 = () => interopDefault(import('../pages/sku/category/index.vue' /* webpackChunkName: "pages/sku/category/index" */))
const _0f0a37f7 = () => interopDefault(import('../pages/sku/exercise/index.vue' /* webpackChunkName: "pages/sku/exercise/index" */))
const _10ad62b0 = () => interopDefault(import('../pages/sku/interactome/index.vue' /* webpackChunkName: "pages/sku/interactome/index" */))
const _b32a93c0 = () => interopDefault(import('../pages/sku/mapper/index.vue' /* webpackChunkName: "pages/sku/mapper/index" */))
const _f75584ca = () => interopDefault(import('../pages/sku/panel/index.vue' /* webpackChunkName: "pages/sku/panel/index" */))
const _d42aceae = () => interopDefault(import('../pages/sku/score/index.vue' /* webpackChunkName: "pages/sku/score/index" */))
const _3e34e24a = () => interopDefault(import('../pages/sku/sub-category/index.vue' /* webpackChunkName: "pages/sku/sub-category/index" */))
const _0fb33b04 = () => interopDefault(import('../pages/sku/traits/index.vue' /* webpackChunkName: "pages/sku/traits/index" */))
const _7de99eac = () => interopDefault(import('../pages/sportgenomic/report-mapper/index.vue' /* webpackChunkName: "pages/sportgenomic/report-mapper/index" */))
const _06484506 = () => interopDefault(import('../pages/user/interface.ts' /* webpackChunkName: "pages/user/interface" */))
const _0736571f = () => interopDefault(import('../pages/360/category/add/index.vue' /* webpackChunkName: "pages/360/category/add/index" */))
const _2e99d862 = () => interopDefault(import('../pages/360/category/edit/index.vue' /* webpackChunkName: "pages/360/category/edit/index" */))
const _76cadf3d = () => interopDefault(import('../pages/360/category/view/index.vue' /* webpackChunkName: "pages/360/category/view/index" */))
const _4a41066c = () => interopDefault(import('../pages/360/interactome/add/index.vue' /* webpackChunkName: "pages/360/interactome/add/index" */))
const _5fd52fd2 = () => interopDefault(import('../pages/360/interactome/edit/index.vue' /* webpackChunkName: "pages/360/interactome/edit/index" */))
const _18466ef2 = () => interopDefault(import('../pages/360/interactome/view/index.vue' /* webpackChunkName: "pages/360/interactome/view/index" */))
const _44a0e37d = () => interopDefault(import('../pages/360/mapper/acl.ts' /* webpackChunkName: "pages/360/mapper/acl" */))
const _180dc63d = () => interopDefault(import('../pages/360/panel/add/index.vue' /* webpackChunkName: "pages/360/panel/add/index" */))
const _38b04d04 = () => interopDefault(import('../pages/360/panel/edit/index.vue' /* webpackChunkName: "pages/360/panel/edit/index" */))
const _1531f4c4 = () => interopDefault(import('../pages/360/panel/figure/index.vue' /* webpackChunkName: "pages/360/panel/figure/index" */))
const _fe3d5842 = () => interopDefault(import('../pages/360/panel/view/index.vue' /* webpackChunkName: "pages/360/panel/view/index" */))
const _5a42ea4b = () => interopDefault(import('../pages/360/score/add/index.vue' /* webpackChunkName: "pages/360/score/add/index" */))
const _0561674c = () => interopDefault(import('../pages/360/sub-category/add/index.vue' /* webpackChunkName: "pages/360/sub-category/add/index" */))
const _145e6456 = () => interopDefault(import('../pages/360/sub-category/edit/index.vue' /* webpackChunkName: "pages/360/sub-category/edit/index" */))
const _3e01d4b0 = () => interopDefault(import('../pages/360/sub-category/view/index.vue' /* webpackChunkName: "pages/360/sub-category/view/index" */))
const _66ed71f4 = () => interopDefault(import('../pages/360/traits/add/index.vue' /* webpackChunkName: "pages/360/traits/add/index" */))
const _13a4e55b = () => interopDefault(import('../pages/360/traits/edit/index.vue' /* webpackChunkName: "pages/360/traits/edit/index" */))
const _5bd5ec36 = () => interopDefault(import('../pages/360/traits/view/index.vue' /* webpackChunkName: "pages/360/traits/view/index" */))
const _04ac3690 = () => interopDefault(import('../pages/appointment/consultation/add/index.vue' /* webpackChunkName: "pages/appointment/consultation/add/index" */))
const _53a2cb83 = () => interopDefault(import('../pages/appointment/counselor/add/index.vue' /* webpackChunkName: "pages/appointment/counselor/add/index" */))
const _6fbbf07e = () => interopDefault(import('../pages/appointment/counselor/edit/index.vue' /* webpackChunkName: "pages/appointment/counselor/edit/index" */))
const _70bcf55c = () => interopDefault(import('../pages/appointment/counselor/performance/index.vue' /* webpackChunkName: "pages/appointment/counselor/performance/index" */))
const _76c8d630 = () => interopDefault(import('../pages/appointment/counselor/profession/index.vue' /* webpackChunkName: "pages/appointment/counselor/profession/index" */))
const _9026114e = () => interopDefault(import('../pages/appointment/counselor/view/index.vue' /* webpackChunkName: "pages/appointment/counselor/view/index" */))
const _5f4f759c = () => interopDefault(import('../pages/asaxperience/merchant/add/index.vue' /* webpackChunkName: "pages/asaxperience/merchant/add/index" */))
const _5e78c5a3 = () => interopDefault(import('../pages/asaxperience/merchant/category/index.vue' /* webpackChunkName: "pages/asaxperience/merchant/category/index" */))
const _ec94a6a2 = () => interopDefault(import('../pages/asaxperience/merchant/edit/index.vue' /* webpackChunkName: "pages/asaxperience/merchant/edit/index" */))
const _6d72ac0e = () => interopDefault(import('../pages/dna/pdf-report/acl.ts' /* webpackChunkName: "pages/dna/pdf-report/acl" */))
const _3bf34cde = () => interopDefault(import('../pages/ehr/lab/result-type/index.vue' /* webpackChunkName: "pages/ehr/lab/result-type/index" */))
const _a263a1cc = () => interopDefault(import('../pages/ehr/mcu/add/index.vue' /* webpackChunkName: "pages/ehr/mcu/add/index" */))
const _dd1f1504 = () => interopDefault(import('../pages/ehr/mcu/patient/index.vue' /* webpackChunkName: "pages/ehr/mcu/patient/index" */))
const _24d3e240 = () => interopDefault(import('../pages/kit/batch/view/index.vue' /* webpackChunkName: "pages/kit/batch/view/index" */))
const _7586a01d = () => interopDefault(import('../pages/master/data/product-type/index.vue' /* webpackChunkName: "pages/master/data/product-type/index" */))
const _c7bf53d4 = () => interopDefault(import('../pages/master/dictionary/content/index.vue' /* webpackChunkName: "pages/master/dictionary/content/index" */))
const _5e7ce155 = () => interopDefault(import('../pages/media/music/add/index.vue' /* webpackChunkName: "pages/media/music/add/index" */))
const _7fb6d628 = () => interopDefault(import('../pages/media/music/edit/index.vue' /* webpackChunkName: "pages/media/music/edit/index" */))
const _fc0a8198 = () => interopDefault(import('../pages/media/podcast/add/index.vue' /* webpackChunkName: "pages/media/podcast/add/index" */))
const _0c6272ed = () => interopDefault(import('../pages/media/podcast/edit/index.vue' /* webpackChunkName: "pages/media/podcast/edit/index" */))
const _7a0fb2da = () => interopDefault(import('../pages/nutrigenetic/category/add/index.vue' /* webpackChunkName: "pages/nutrigenetic/category/add/index" */))
const _29dc1124 = () => interopDefault(import('../pages/nutrigenetic/category/edit/index.vue' /* webpackChunkName: "pages/nutrigenetic/category/edit/index" */))
const _3342fe49 = () => interopDefault(import('../pages/nutrigenetic/category/view/index.vue' /* webpackChunkName: "pages/nutrigenetic/category/view/index" */))
const _36274b49 = () => interopDefault(import('../pages/nutrigenetic/panel/add/index.vue' /* webpackChunkName: "pages/nutrigenetic/panel/add/index" */))
const _44712d10 = () => interopDefault(import('../pages/nutrigenetic/panel/edit/index.vue' /* webpackChunkName: "pages/nutrigenetic/panel/edit/index" */))
const _9dae55dc = () => interopDefault(import('../pages/nutrigenetic/panel/figure/index.vue' /* webpackChunkName: "pages/nutrigenetic/panel/figure/index" */))
const _25f87053 = () => interopDefault(import('../pages/nutrigenetic/panel/view/index.vue' /* webpackChunkName: "pages/nutrigenetic/panel/view/index" */))
const _785c6f57 = () => interopDefault(import('../pages/nutrigenetic/score/add/index.vue' /* webpackChunkName: "pages/nutrigenetic/score/add/index" */))
const _64065cc0 = () => interopDefault(import('../pages/nutrigenetic/sub-category/add/index.vue' /* webpackChunkName: "pages/nutrigenetic/sub-category/add/index" */))
const _6bca86e1 = () => interopDefault(import('../pages/nutrigenetic/sub-category/edit/index.vue' /* webpackChunkName: "pages/nutrigenetic/sub-category/edit/index" */))
const _9808e488 = () => interopDefault(import('../pages/nutrigenetic/sub-category/view/index.vue' /* webpackChunkName: "pages/nutrigenetic/sub-category/view/index" */))
const _71a0637a = () => interopDefault(import('../pages/nutrigenetic/traits/add/index.vue' /* webpackChunkName: "pages/nutrigenetic/traits/add/index" */))
const _11715767 = () => interopDefault(import('../pages/nutrigenetic/traits/edit/index.vue' /* webpackChunkName: "pages/nutrigenetic/traits/edit/index" */))
const _59a25e42 = () => interopDefault(import('../pages/nutrigenetic/traits/view/index.vue' /* webpackChunkName: "pages/nutrigenetic/traits/view/index" */))
const _87361b3c = () => interopDefault(import('../pages/partner/bank/add/index.vue' /* webpackChunkName: "pages/partner/bank/add/index" */))
const _def601c8 = () => interopDefault(import('../pages/partner/role/add/index.vue' /* webpackChunkName: "pages/partner/role/add/index" */))
const _93a67a12 = () => interopDefault(import('../pages/partnership/report/category/index.vue' /* webpackChunkName: "pages/partnership/report/category/index" */))
const _2c4293f0 = () => interopDefault(import('../pages/prodigy/category/add/index.vue' /* webpackChunkName: "pages/prodigy/category/add/index" */))
const _be0552ce = () => interopDefault(import('../pages/prodigy/category/edit/index.vue' /* webpackChunkName: "pages/prodigy/category/edit/index" */))
const _5f4acad2 = () => interopDefault(import('../pages/prodigy/category/guideline/index.vue' /* webpackChunkName: "pages/prodigy/category/guideline/index" */))
const _2da34518 = () => interopDefault(import('../pages/prodigy/category/view/index.vue' /* webpackChunkName: "pages/prodigy/category/view/index" */))
const _39726a1e = () => interopDefault(import('../pages/prodigy/localization/edit/index.vue' /* webpackChunkName: "pages/prodigy/localization/edit/index" */))
const _efe41b9c = () => interopDefault(import('../pages/prodigy/localization/guideline/index.vue' /* webpackChunkName: "pages/prodigy/localization/guideline/index" */))
const _fcb91e0e = () => interopDefault(import('../pages/prodigy/localization/view/index.vue' /* webpackChunkName: "pages/prodigy/localization/view/index" */))
const _1a41ff9a = () => interopDefault(import('../pages/prodigy/mapper/guideline/index.vue' /* webpackChunkName: "pages/prodigy/mapper/guideline/index" */))
const _86f55ba6 = () => interopDefault(import('../pages/prodigy/panel/edit/index.vue' /* webpackChunkName: "pages/prodigy/panel/edit/index" */))
const _1e24bb83 = () => interopDefault(import('../pages/prodigy/panel/guideline/index.vue' /* webpackChunkName: "pages/prodigy/panel/guideline/index" */))
const _04b65908 = () => interopDefault(import('../pages/prodigy/panel/view/index.vue' /* webpackChunkName: "pages/prodigy/panel/view/index" */))
const _bed7c7fc = () => interopDefault(import('../pages/prodigy/score/add/index.vue' /* webpackChunkName: "pages/prodigy/score/add/index" */))
const _7e16a042 = () => interopDefault(import('../pages/prodigy/score/edit/index.vue' /* webpackChunkName: "pages/prodigy/score/edit/index" */))
const _69861d11 = () => interopDefault(import('../pages/prodigy/score/guideline/index.vue' /* webpackChunkName: "pages/prodigy/score/guideline/index" */))
const _0925b6ba = () => interopDefault(import('../pages/prodigy/score/view/index.vue' /* webpackChunkName: "pages/prodigy/score/view/index" */))
const _505e4c2f = () => interopDefault(import('../pages/prodigy/traits/add/index.vue' /* webpackChunkName: "pages/prodigy/traits/add/index" */))
const _0a708552 = () => interopDefault(import('../pages/prodigy/traits/edit/index.vue' /* webpackChunkName: "pages/prodigy/traits/edit/index" */))
const _1164b77e = () => interopDefault(import('../pages/prodigy/traits/guideline/index.vue' /* webpackChunkName: "pages/prodigy/traits/guideline/index" */))
const _52a18c2d = () => interopDefault(import('../pages/prodigy/traits/view/index.vue' /* webpackChunkName: "pages/prodigy/traits/view/index" */))
const _76f33826 = () => interopDefault(import('../pages/product/type/acl.ts' /* webpackChunkName: "pages/product/type/acl" */))
const _5a639f59 = () => interopDefault(import('../pages/product/type/add/index.vue' /* webpackChunkName: "pages/product/type/add/index" */))
const _0343b1e0 = () => interopDefault(import('../pages/product/type/data.ts' /* webpackChunkName: "pages/product/type/data" */))
const _41159768 = () => interopDefault(import('../pages/product/type/edit/index.vue' /* webpackChunkName: "pages/product/type/edit/index" */))
const _73512797 = () => interopDefault(import('../pages/promotion/banner/add/index.vue' /* webpackChunkName: "pages/promotion/banner/add/index" */))
const _e3ebc476 = () => interopDefault(import('../pages/promotion/banner/view/index.vue' /* webpackChunkName: "pages/promotion/banner/view/index" */))
const _6c392f2c = () => interopDefault(import('../pages/promotion/bundling/add/index.vue' /* webpackChunkName: "pages/promotion/bundling/add/index" */))
const _7ce21f12 = () => interopDefault(import('../pages/promotion/bundling/edit/index.vue' /* webpackChunkName: "pages/promotion/bundling/edit/index" */))
const _a15363bc = () => interopDefault(import('../pages/promotion/voucher/acl.ts' /* webpackChunkName: "pages/promotion/voucher/acl" */))
const _6140135e = () => interopDefault(import('../pages/promotion/voucher/tracking/index.vue' /* webpackChunkName: "pages/promotion/voucher/tracking/index" */))
const _7012dba2 = () => interopDefault(import('../pages/sku/category/add/index.vue' /* webpackChunkName: "pages/sku/category/add/index" */))
const _f43e015c = () => interopDefault(import('../pages/sku/category/edit/index.vue' /* webpackChunkName: "pages/sku/category/edit/index" */))
const _63dbf3a6 = () => interopDefault(import('../pages/sku/category/view/index.vue' /* webpackChunkName: "pages/sku/category/view/index" */))
const _87d35cee = () => interopDefault(import('../pages/sku/exercise/add/index.vue' /* webpackChunkName: "pages/sku/exercise/add/index" */))
const _15b92b38 = () => interopDefault(import('../pages/sku/exercise/edit/index.vue' /* webpackChunkName: "pages/sku/exercise/edit/index" */))
const _5dea3213 = () => interopDefault(import('../pages/sku/exercise/view/index.vue' /* webpackChunkName: "pages/sku/exercise/view/index" */))
const _7cf8a7ba = () => interopDefault(import('../pages/sku/interactome/add/index.vue' /* webpackChunkName: "pages/sku/interactome/add/index" */))
const _71219b27 = () => interopDefault(import('../pages/sku/interactome/edit/index.vue' /* webpackChunkName: "pages/sku/interactome/edit/index" */))
const _8d5abbfc = () => interopDefault(import('../pages/sku/interactome/view/index.vue' /* webpackChunkName: "pages/sku/interactome/view/index" */))
const _5a6ef66d = () => interopDefault(import('../pages/sku/mapper/acl.ts' /* webpackChunkName: "pages/sku/mapper/acl" */))
const _394435a6 = () => interopDefault(import('../pages/sku/panel/add/index.vue' /* webpackChunkName: "pages/sku/panel/add/index" */))
const _5137e7d8 = () => interopDefault(import('../pages/sku/panel/edit/index.vue' /* webpackChunkName: "pages/sku/panel/edit/index" */))
const _93b17ea4 = () => interopDefault(import('../pages/sku/panel/figure/index.vue' /* webpackChunkName: "pages/sku/panel/figure/index" */))
const _1f9512ef = () => interopDefault(import('../pages/sku/panel/view/index.vue' /* webpackChunkName: "pages/sku/panel/view/index" */))
const _2593093b = () => interopDefault(import('../pages/sku/score/add/index.vue' /* webpackChunkName: "pages/sku/score/add/index" */))
const _b324cb48 = () => interopDefault(import('../pages/sku/sub-category/add/index.vue' /* webpackChunkName: "pages/sku/sub-category/add/index" */))
const _764afcc5 = () => interopDefault(import('../pages/sku/sub-category/edit/index.vue' /* webpackChunkName: "pages/sku/sub-category/edit/index" */))
const _8307f8c0 = () => interopDefault(import('../pages/sku/sub-category/view/index.vue' /* webpackChunkName: "pages/sku/sub-category/view/index" */))
const _6b3d0616 = () => interopDefault(import('../pages/sku/traits/add/index.vue' /* webpackChunkName: "pages/sku/traits/add/index" */))
const _4b69084b = () => interopDefault(import('../pages/sku/traits/edit/index.vue' /* webpackChunkName: "pages/sku/traits/edit/index" */))
const _d8cbe1b4 = () => interopDefault(import('../pages/sku/traits/view/index.vue' /* webpackChunkName: "pages/sku/traits/view/index" */))
const _36d77991 = () => interopDefault(import('../pages/asaxperience/merchant/voucher/add/index.vue' /* webpackChunkName: "pages/asaxperience/merchant/voucher/add/index" */))
const _19c5f7a0 = () => interopDefault(import('../pages/asaxperience/merchant/voucher/edit/index.vue' /* webpackChunkName: "pages/asaxperience/merchant/voucher/edit/index" */))
const _432479c6 = () => interopDefault(import('../pages/asaxperience/merchant/voucher/tracking/index.vue' /* webpackChunkName: "pages/asaxperience/merchant/voucher/tracking/index" */))
const _8e256d2c = () => interopDefault(import('../pages/master/data/report/category/index.vue' /* webpackChunkName: "pages/master/data/report/category/index" */))
const _220a532d = () => interopDefault(import('../pages/master/data/report/mapper/index.vue' /* webpackChunkName: "pages/master/data/report/mapper/index" */))
const _e95a4fde = () => interopDefault(import('../pages/master/data/report/traits/index.vue' /* webpackChunkName: "pages/master/data/report/traits/index" */))
const _45b43fd4 = () => interopDefault(import('../pages/product/type/add/acl.ts' /* webpackChunkName: "pages/product/type/add/acl" */))
const _0ca39ff2 = () => interopDefault(import('../pages/product/type/add/data.ts' /* webpackChunkName: "pages/product/type/add/data" */))
const _2f53ecb6 = () => interopDefault(import('../pages/master/data/report/category/edit/index.vue' /* webpackChunkName: "pages/master/data/report/category/edit/index" */))
const _3a328b5e = () => interopDefault(import('../pages/master/data/report/traits/edit/index.vue' /* webpackChunkName: "pages/master/data/report/traits/edit/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2941323b = () => interopDefault(import('../pages/partnership/report/category/view/_id.vue' /* webpackChunkName: "pages/partnership/report/category/view/_id" */))
const _32e4e29e = () => interopDefault(import('../pages/360/score/edit/_id.vue' /* webpackChunkName: "pages/360/score/edit/_id" */))
const _ec60378e = () => interopDefault(import('../pages/360/score/view/_id.vue' /* webpackChunkName: "pages/360/score/view/_id" */))
const _7fd713fe = () => interopDefault(import('../pages/master/dictionary/content/_id/index.vue' /* webpackChunkName: "pages/master/dictionary/content/_id/index" */))
const _7f7ee7dc = () => interopDefault(import('../pages/nutrigenetic/score/edit/_id.vue' /* webpackChunkName: "pages/nutrigenetic/score/edit/_id" */))
const _172b8dad = () => interopDefault(import('../pages/nutrigenetic/score/view/_id.vue' /* webpackChunkName: "pages/nutrigenetic/score/view/_id" */))
const _42c5b4a4 = () => interopDefault(import('../pages/sku/score/edit/_id.vue' /* webpackChunkName: "pages/sku/score/edit/_id" */))
const _35882749 = () => interopDefault(import('../pages/sku/score/view/_id.vue' /* webpackChunkName: "pages/sku/score/view/_id" */))
const _5876236e = () => interopDefault(import('../pages/master/dictionary/content/_id/edit/index.vue' /* webpackChunkName: "pages/master/dictionary/content/_id/edit/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/appointment",
    component: _605d06e8,
    name: "appointment"
  }, {
    path: "/article",
    component: _1ffec302,
    name: "article"
  }, {
    path: "/customer",
    component: _fb757d6a,
    name: "customer"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/glossary",
    component: _a3eb88ea,
    name: "glossary"
  }, {
    path: "/home-service",
    component: _53b1c8f4,
    name: "home-service"
  }, {
    path: "/kit",
    component: _32be8142,
    name: "kit"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/logout",
    component: _25f57c77,
    name: "logout"
  }, {
    path: "/news",
    component: _076ad200,
    name: "news"
  }, {
    path: "/order",
    component: _91fa2352,
    name: "order"
  }, {
    path: "/partner",
    component: _49788fd1,
    name: "partner"
  }, {
    path: "/partnership",
    component: _e99ef7e6,
    name: "partnership"
  }, {
    path: "/promotion",
    component: _7071166c,
    name: "promotion"
  }, {
    path: "/reswab",
    component: _355298b6,
    name: "reswab"
  }, {
    path: "/sales",
    component: _27b31a95,
    name: "sales"
  }, {
    path: "/setting",
    component: _a75f114e,
    name: "setting"
  }, {
    path: "/user",
    component: _152321f8,
    name: "user"
  }, {
    path: "/verification",
    component: _b4bf1370,
    name: "verification"
  }, {
    path: "/360/acl",
    component: _3449c657,
    name: "360-acl"
  }, {
    path: "/360/category",
    component: _09ef0ee6,
    name: "360-category"
  }, {
    path: "/360/interactome",
    component: _8035a890,
    name: "360-interactome"
  }, {
    path: "/360/mapper",
    component: _2e5cef10,
    name: "360-mapper"
  }, {
    path: "/360/panel",
    component: _ac7fb2aa,
    name: "360-panel"
  }, {
    path: "/360/score",
    component: _8954fc8e,
    name: "360-score"
  }, {
    path: "/360/sub-category",
    component: _7d74a73a,
    name: "360-sub-category"
  }, {
    path: "/360/traits",
    component: _d0b51818,
    name: "360-traits"
  }, {
    path: "/analytic/tracking",
    component: _847b1f7c,
    name: "analytic-tracking"
  }, {
    path: "/appointment/consultation",
    component: _700cbaa6,
    name: "appointment-consultation"
  }, {
    path: "/appointment/counselor",
    component: _5686bef1,
    name: "appointment-counselor"
  }, {
    path: "/appointment/feedback",
    component: _37bc39a2,
    name: "appointment-feedback"
  }, {
    path: "/appointment/setting",
    component: _7c40d7e9,
    name: "appointment-setting"
  }, {
    path: "/article/add",
    component: _523397de,
    name: "article-add"
  }, {
    path: "/article/category",
    component: _05f84a24,
    name: "article-category"
  }, {
    path: "/article/edit",
    component: _54e599b0,
    name: "article-edit"
  }, {
    path: "/article/sub-category",
    component: _0263d551,
    name: "article-sub-category"
  }, {
    path: "/asaxperience/merchant",
    component: _08a26820,
    name: "asaxperience-merchant"
  }, {
    path: "/customer/view",
    component: _baf84582,
    name: "customer-view"
  }, {
    path: "/dna-report/update",
    component: _0c69d452,
    name: "dna-report-update"
  }, {
    path: "/dna/pdf-report",
    component: _f45c71d8,
    name: "dna-pdf-report"
  }, {
    path: "/ehr/mcu",
    component: _45acf3f0,
    name: "ehr-mcu"
  }, {
    path: "/home-service/settings",
    component: _833baf98,
    name: "home-service-settings"
  }, {
    path: "/kit/acl",
    component: _7466e4e4,
    name: "kit-acl"
  }, {
    path: "/kit/activation",
    component: _9578a6c8,
    name: "kit-activation"
  }, {
    path: "/kit/batch",
    component: _f15e61ec,
    name: "kit-batch"
  }, {
    path: "/kit/view",
    component: _386fe8aa,
    name: "kit-view"
  }, {
    path: "/media/music",
    component: _57688dc3,
    name: "media-music"
  }, {
    path: "/media/podcast",
    component: _77063322,
    name: "media-podcast"
  }, {
    path: "/news/add",
    component: _448e0412,
    name: "news-add"
  }, {
    path: "/news/edit",
    component: _c7906c62,
    name: "news-edit"
  }, {
    path: "/nutrigenetic/category",
    component: _ef37bbfe,
    name: "nutrigenetic-category"
  }, {
    path: "/nutrigenetic/config",
    component: _965d7f7a,
    name: "nutrigenetic-config"
  }, {
    path: "/nutrigenetic/mapper",
    component: _15bc6584,
    name: "nutrigenetic-mapper"
  }, {
    path: "/nutrigenetic/panel",
    component: _4e1e11b7,
    name: "nutrigenetic-panel"
  }, {
    path: "/nutrigenetic/report-mapper",
    component: _88faf5a6,
    name: "nutrigenetic-report-mapper"
  }, {
    path: "/nutrigenetic/score",
    component: _5fb36cc5,
    name: "nutrigenetic-score"
  }, {
    path: "/nutrigenetic/sub-category",
    component: _393676ae,
    name: "nutrigenetic-sub-category"
  }, {
    path: "/nutrigenetic/traits",
    component: _7f04ea68,
    name: "nutrigenetic-traits"
  }, {
    path: "/partner/add",
    component: _34fda73a,
    name: "partner-add"
  }, {
    path: "/partner/bank",
    component: _da15a560,
    name: "partner-bank"
  }, {
    path: "/partner/order",
    component: _5393a910,
    name: "partner-order"
  }, {
    path: "/partner/role",
    component: _2f3ca70a,
    name: "partner-role"
  }, {
    path: "/partner/view",
    component: _61dab279,
    name: "partner-view"
  }, {
    path: "/prodigy/category",
    component: _79029814,
    name: "prodigy-category"
  }, {
    path: "/prodigy/localization",
    component: _6c5a7d5e,
    name: "prodigy-localization"
  }, {
    path: "/prodigy/mapper",
    component: _52ccf0b9,
    name: "prodigy-mapper"
  }, {
    path: "/prodigy/panel",
    component: _ec36683c,
    name: "prodigy-panel"
  }, {
    path: "/prodigy/score",
    component: _c90bb220,
    name: "prodigy-score"
  }, {
    path: "/prodigy/traits",
    component: _87d514c6,
    name: "prodigy-traits"
  }, {
    path: "/product/category",
    component: _59366d8b,
    name: "product-category"
  }, {
    path: "/product/product-data",
    component: _41984ab3,
    name: "product-product-data"
  }, {
    path: "/product/purchase",
    component: _6790cdce,
    name: "product-purchase"
  }, {
    path: "/product/report",
    component: _e11a627e,
    name: "product-report"
  }, {
    path: "/product/type",
    component: _2946edc7,
    name: "product-type"
  }, {
    path: "/promotion/banner",
    component: _504e4505,
    name: "promotion-banner"
  }, {
    path: "/promotion/bundling",
    component: _6fefe758,
    name: "promotion-bundling"
  }, {
    path: "/promotion/referral",
    component: _2dfcb0b6,
    name: "promotion-referral"
  }, {
    path: "/promotion/voucher",
    component: _07fb736a,
    name: "promotion-voucher"
  }, {
    path: "/sku/category",
    component: _b27e88c6,
    name: "sku-category"
  }, {
    path: "/sku/exercise",
    component: _0f0a37f7,
    name: "sku-exercise"
  }, {
    path: "/sku/interactome",
    component: _10ad62b0,
    name: "sku-interactome"
  }, {
    path: "/sku/mapper",
    component: _b32a93c0,
    name: "sku-mapper"
  }, {
    path: "/sku/panel",
    component: _f75584ca,
    name: "sku-panel"
  }, {
    path: "/sku/score",
    component: _d42aceae,
    name: "sku-score"
  }, {
    path: "/sku/sub-category",
    component: _3e34e24a,
    name: "sku-sub-category"
  }, {
    path: "/sku/traits",
    component: _0fb33b04,
    name: "sku-traits"
  }, {
    path: "/sportgenomic/report-mapper",
    component: _7de99eac,
    name: "sportgenomic-report-mapper"
  }, {
    path: "/user/interface",
    component: _06484506,
    name: "user-interface"
  }, {
    path: "/360/category/add",
    component: _0736571f,
    name: "360-category-add"
  }, {
    path: "/360/category/edit",
    component: _2e99d862,
    name: "360-category-edit"
  }, {
    path: "/360/category/view",
    component: _76cadf3d,
    name: "360-category-view"
  }, {
    path: "/360/interactome/add",
    component: _4a41066c,
    name: "360-interactome-add"
  }, {
    path: "/360/interactome/edit",
    component: _5fd52fd2,
    name: "360-interactome-edit"
  }, {
    path: "/360/interactome/view",
    component: _18466ef2,
    name: "360-interactome-view"
  }, {
    path: "/360/mapper/acl",
    component: _44a0e37d,
    name: "360-mapper-acl"
  }, {
    path: "/360/panel/add",
    component: _180dc63d,
    name: "360-panel-add"
  }, {
    path: "/360/panel/edit",
    component: _38b04d04,
    name: "360-panel-edit"
  }, {
    path: "/360/panel/figure",
    component: _1531f4c4,
    name: "360-panel-figure"
  }, {
    path: "/360/panel/view",
    component: _fe3d5842,
    name: "360-panel-view"
  }, {
    path: "/360/score/add",
    component: _5a42ea4b,
    name: "360-score-add"
  }, {
    path: "/360/sub-category/add",
    component: _0561674c,
    name: "360-sub-category-add"
  }, {
    path: "/360/sub-category/edit",
    component: _145e6456,
    name: "360-sub-category-edit"
  }, {
    path: "/360/sub-category/view",
    component: _3e01d4b0,
    name: "360-sub-category-view"
  }, {
    path: "/360/traits/add",
    component: _66ed71f4,
    name: "360-traits-add"
  }, {
    path: "/360/traits/edit",
    component: _13a4e55b,
    name: "360-traits-edit"
  }, {
    path: "/360/traits/view",
    component: _5bd5ec36,
    name: "360-traits-view"
  }, {
    path: "/appointment/consultation/add",
    component: _04ac3690,
    name: "appointment-consultation-add"
  }, {
    path: "/appointment/counselor/add",
    component: _53a2cb83,
    name: "appointment-counselor-add"
  }, {
    path: "/appointment/counselor/edit",
    component: _6fbbf07e,
    name: "appointment-counselor-edit"
  }, {
    path: "/appointment/counselor/performance",
    component: _70bcf55c,
    name: "appointment-counselor-performance"
  }, {
    path: "/appointment/counselor/profession",
    component: _76c8d630,
    name: "appointment-counselor-profession"
  }, {
    path: "/appointment/counselor/view",
    component: _9026114e,
    name: "appointment-counselor-view"
  }, {
    path: "/asaxperience/merchant/add",
    component: _5f4f759c,
    name: "asaxperience-merchant-add"
  }, {
    path: "/asaxperience/merchant/category",
    component: _5e78c5a3,
    name: "asaxperience-merchant-category"
  }, {
    path: "/asaxperience/merchant/edit",
    component: _ec94a6a2,
    name: "asaxperience-merchant-edit"
  }, {
    path: "/dna/pdf-report/acl",
    component: _6d72ac0e,
    name: "dna-pdf-report-acl"
  }, {
    path: "/ehr/lab/result-type",
    component: _3bf34cde,
    name: "ehr-lab-result-type"
  }, {
    path: "/ehr/mcu/add",
    component: _a263a1cc,
    name: "ehr-mcu-add"
  }, {
    path: "/ehr/mcu/patient",
    component: _dd1f1504,
    name: "ehr-mcu-patient"
  }, {
    path: "/kit/batch/view",
    component: _24d3e240,
    name: "kit-batch-view"
  }, {
    path: "/master/data/product-type",
    component: _7586a01d,
    name: "master-data-product-type"
  }, {
    path: "/master/dictionary/content",
    component: _c7bf53d4,
    name: "master-dictionary-content"
  }, {
    path: "/media/music/add",
    component: _5e7ce155,
    name: "media-music-add"
  }, {
    path: "/media/music/edit",
    component: _7fb6d628,
    name: "media-music-edit"
  }, {
    path: "/media/podcast/add",
    component: _fc0a8198,
    name: "media-podcast-add"
  }, {
    path: "/media/podcast/edit",
    component: _0c6272ed,
    name: "media-podcast-edit"
  }, {
    path: "/nutrigenetic/category/add",
    component: _7a0fb2da,
    name: "nutrigenetic-category-add"
  }, {
    path: "/nutrigenetic/category/edit",
    component: _29dc1124,
    name: "nutrigenetic-category-edit"
  }, {
    path: "/nutrigenetic/category/view",
    component: _3342fe49,
    name: "nutrigenetic-category-view"
  }, {
    path: "/nutrigenetic/panel/add",
    component: _36274b49,
    name: "nutrigenetic-panel-add"
  }, {
    path: "/nutrigenetic/panel/edit",
    component: _44712d10,
    name: "nutrigenetic-panel-edit"
  }, {
    path: "/nutrigenetic/panel/figure",
    component: _9dae55dc,
    name: "nutrigenetic-panel-figure"
  }, {
    path: "/nutrigenetic/panel/view",
    component: _25f87053,
    name: "nutrigenetic-panel-view"
  }, {
    path: "/nutrigenetic/score/add",
    component: _785c6f57,
    name: "nutrigenetic-score-add"
  }, {
    path: "/nutrigenetic/sub-category/add",
    component: _64065cc0,
    name: "nutrigenetic-sub-category-add"
  }, {
    path: "/nutrigenetic/sub-category/edit",
    component: _6bca86e1,
    name: "nutrigenetic-sub-category-edit"
  }, {
    path: "/nutrigenetic/sub-category/view",
    component: _9808e488,
    name: "nutrigenetic-sub-category-view"
  }, {
    path: "/nutrigenetic/traits/add",
    component: _71a0637a,
    name: "nutrigenetic-traits-add"
  }, {
    path: "/nutrigenetic/traits/edit",
    component: _11715767,
    name: "nutrigenetic-traits-edit"
  }, {
    path: "/nutrigenetic/traits/view",
    component: _59a25e42,
    name: "nutrigenetic-traits-view"
  }, {
    path: "/partner/bank/add",
    component: _87361b3c,
    name: "partner-bank-add"
  }, {
    path: "/partner/role/add",
    component: _def601c8,
    name: "partner-role-add"
  }, {
    path: "/partnership/report/category",
    component: _93a67a12,
    name: "partnership-report-category"
  }, {
    path: "/prodigy/category/add",
    component: _2c4293f0,
    name: "prodigy-category-add"
  }, {
    path: "/prodigy/category/edit",
    component: _be0552ce,
    name: "prodigy-category-edit"
  }, {
    path: "/prodigy/category/guideline",
    component: _5f4acad2,
    name: "prodigy-category-guideline"
  }, {
    path: "/prodigy/category/view",
    component: _2da34518,
    name: "prodigy-category-view"
  }, {
    path: "/prodigy/localization/edit",
    component: _39726a1e,
    name: "prodigy-localization-edit"
  }, {
    path: "/prodigy/localization/guideline",
    component: _efe41b9c,
    name: "prodigy-localization-guideline"
  }, {
    path: "/prodigy/localization/view",
    component: _fcb91e0e,
    name: "prodigy-localization-view"
  }, {
    path: "/prodigy/mapper/guideline",
    component: _1a41ff9a,
    name: "prodigy-mapper-guideline"
  }, {
    path: "/prodigy/panel/edit",
    component: _86f55ba6,
    name: "prodigy-panel-edit"
  }, {
    path: "/prodigy/panel/guideline",
    component: _1e24bb83,
    name: "prodigy-panel-guideline"
  }, {
    path: "/prodigy/panel/view",
    component: _04b65908,
    name: "prodigy-panel-view"
  }, {
    path: "/prodigy/score/add",
    component: _bed7c7fc,
    name: "prodigy-score-add"
  }, {
    path: "/prodigy/score/edit",
    component: _7e16a042,
    name: "prodigy-score-edit"
  }, {
    path: "/prodigy/score/guideline",
    component: _69861d11,
    name: "prodigy-score-guideline"
  }, {
    path: "/prodigy/score/view",
    component: _0925b6ba,
    name: "prodigy-score-view"
  }, {
    path: "/prodigy/traits/add",
    component: _505e4c2f,
    name: "prodigy-traits-add"
  }, {
    path: "/prodigy/traits/edit",
    component: _0a708552,
    name: "prodigy-traits-edit"
  }, {
    path: "/prodigy/traits/guideline",
    component: _1164b77e,
    name: "prodigy-traits-guideline"
  }, {
    path: "/prodigy/traits/view",
    component: _52a18c2d,
    name: "prodigy-traits-view"
  }, {
    path: "/product/type/acl",
    component: _76f33826,
    name: "product-type-acl"
  }, {
    path: "/product/type/add",
    component: _5a639f59,
    name: "product-type-add"
  }, {
    path: "/product/type/data",
    component: _0343b1e0,
    name: "product-type-data"
  }, {
    path: "/product/type/edit",
    component: _41159768,
    name: "product-type-edit"
  }, {
    path: "/promotion/banner/add",
    component: _73512797,
    name: "promotion-banner-add"
  }, {
    path: "/promotion/banner/view",
    component: _e3ebc476,
    name: "promotion-banner-view"
  }, {
    path: "/promotion/bundling/add",
    component: _6c392f2c,
    name: "promotion-bundling-add"
  }, {
    path: "/promotion/bundling/edit",
    component: _7ce21f12,
    name: "promotion-bundling-edit"
  }, {
    path: "/promotion/voucher/acl",
    component: _a15363bc,
    name: "promotion-voucher-acl"
  }, {
    path: "/promotion/voucher/tracking",
    component: _6140135e,
    name: "promotion-voucher-tracking"
  }, {
    path: "/sku/category/add",
    component: _7012dba2,
    name: "sku-category-add"
  }, {
    path: "/sku/category/edit",
    component: _f43e015c,
    name: "sku-category-edit"
  }, {
    path: "/sku/category/view",
    component: _63dbf3a6,
    name: "sku-category-view"
  }, {
    path: "/sku/exercise/add",
    component: _87d35cee,
    name: "sku-exercise-add"
  }, {
    path: "/sku/exercise/edit",
    component: _15b92b38,
    name: "sku-exercise-edit"
  }, {
    path: "/sku/exercise/view",
    component: _5dea3213,
    name: "sku-exercise-view"
  }, {
    path: "/sku/interactome/add",
    component: _7cf8a7ba,
    name: "sku-interactome-add"
  }, {
    path: "/sku/interactome/edit",
    component: _71219b27,
    name: "sku-interactome-edit"
  }, {
    path: "/sku/interactome/view",
    component: _8d5abbfc,
    name: "sku-interactome-view"
  }, {
    path: "/sku/mapper/acl",
    component: _5a6ef66d,
    name: "sku-mapper-acl"
  }, {
    path: "/sku/panel/add",
    component: _394435a6,
    name: "sku-panel-add"
  }, {
    path: "/sku/panel/edit",
    component: _5137e7d8,
    name: "sku-panel-edit"
  }, {
    path: "/sku/panel/figure",
    component: _93b17ea4,
    name: "sku-panel-figure"
  }, {
    path: "/sku/panel/view",
    component: _1f9512ef,
    name: "sku-panel-view"
  }, {
    path: "/sku/score/add",
    component: _2593093b,
    name: "sku-score-add"
  }, {
    path: "/sku/sub-category/add",
    component: _b324cb48,
    name: "sku-sub-category-add"
  }, {
    path: "/sku/sub-category/edit",
    component: _764afcc5,
    name: "sku-sub-category-edit"
  }, {
    path: "/sku/sub-category/view",
    component: _8307f8c0,
    name: "sku-sub-category-view"
  }, {
    path: "/sku/traits/add",
    component: _6b3d0616,
    name: "sku-traits-add"
  }, {
    path: "/sku/traits/edit",
    component: _4b69084b,
    name: "sku-traits-edit"
  }, {
    path: "/sku/traits/view",
    component: _d8cbe1b4,
    name: "sku-traits-view"
  }, {
    path: "/asaxperience/merchant/voucher/add",
    component: _36d77991,
    name: "asaxperience-merchant-voucher-add"
  }, {
    path: "/asaxperience/merchant/voucher/edit",
    component: _19c5f7a0,
    name: "asaxperience-merchant-voucher-edit"
  }, {
    path: "/asaxperience/merchant/voucher/tracking",
    component: _432479c6,
    name: "asaxperience-merchant-voucher-tracking"
  }, {
    path: "/master/data/report/category",
    component: _8e256d2c,
    name: "master-data-report-category"
  }, {
    path: "/master/data/report/mapper",
    component: _220a532d,
    name: "master-data-report-mapper"
  }, {
    path: "/master/data/report/traits",
    component: _e95a4fde,
    name: "master-data-report-traits"
  }, {
    path: "/product/type/add/acl",
    component: _45b43fd4,
    name: "product-type-add-acl"
  }, {
    path: "/product/type/add/data",
    component: _0ca39ff2,
    name: "product-type-add-data"
  }, {
    path: "/master/data/report/category/edit",
    component: _2f53ecb6,
    name: "master-data-report-category-edit"
  }, {
    path: "/master/data/report/traits/edit",
    component: _3a328b5e,
    name: "master-data-report-traits-edit"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/partnership/report/category/view/:id?",
    component: _2941323b,
    name: "partnership-report-category-view-id"
  }, {
    path: "/360/score/edit/:id?",
    component: _32e4e29e,
    name: "360-score-edit-id"
  }, {
    path: "/360/score/view/:id?",
    component: _ec60378e,
    name: "360-score-view-id"
  }, {
    path: "/master/dictionary/content/:id",
    component: _7fd713fe,
    name: "master-dictionary-content-id"
  }, {
    path: "/nutrigenetic/score/edit/:id?",
    component: _7f7ee7dc,
    name: "nutrigenetic-score-edit-id"
  }, {
    path: "/nutrigenetic/score/view/:id?",
    component: _172b8dad,
    name: "nutrigenetic-score-view-id"
  }, {
    path: "/sku/score/edit/:id?",
    component: _42c5b4a4,
    name: "sku-score-edit-id"
  }, {
    path: "/sku/score/view/:id?",
    component: _35882749,
    name: "sku-score-view-id"
  }, {
    path: "/master/dictionary/content/:id/edit",
    component: _5876236e,
    name: "master-dictionary-content-id-edit"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
