export const LOCALE = {
  NAME: 'Asa Rén',
  REPORT: {
    NOURISH: {
      NAME: 'Nourish'
    }
  }
};

export const STYLES = {
  '--v-primary-base': '#5164b4',
  '--app-bar-color': `#ffffff`,
  '--app-bar-background': `var(--v-primary-base)`,
  '--app-bar-dropdown-menu-background': `#d0d8ff`,
  '--navigation-drawer-color': '#ffffff',
  '--navigation-drawer-background': `var(--v-primary-base)`,
  '--upload-background-color': `#d0d8ff`
};

export enum MODULE {
  DASHBOARD = 'Dashboard',

  // startof product module
  PRODUCT = 'Product',
  PRODUCT_KIT_CODE = 'Product - Kit Code',
  PRODUCT_RESWAB = 'Product - Reswab',
  PRODUCT_BATCH_NUMBER = 'Product - Batch Number Generator',
  // endof product module

  SETTINGS = 'Settings',
  SETTINGS_USER_MANAGEMENT = 'Settings - User Management',
  SETTINGS_SETTINGS = 'Settings - Settings',

  // startof master data report module
  MASTER_DATA_REPORT = 'Master Data Report',
  MASTER_DATA_REPORT_PRODUCT_TYPE = 'Master Data Report - Report Product Type',
  MASTER_DATA_REPORT_REPORT = 'Master Data Report - Report',
  MASTER_DATA_REPORT_REPORT_MAPPER = 'Master Data Report - Report - Mapper',
  MASTER_DATA_REPORT_REPORT_CATEGORY = 'Master Data Report - Report - Category',
  MASTER_DATA_REPORT_REPORT_TRAITS = 'Master Data Report - Report - Traits',
  MASTER_DATA_REPORT_360 = 'Master Data Report - 360',
  MASTER_DATA_REPORT_360_REPORT_PANEL = 'Master Data Report - 360 - Report Panel',
  MASTER_DATA_REPORT_360_REPORT_CATEGORY = 'Master Data Report - 360 - Report CATEGORY',
  MASTER_DATA_REPORT_360_REPORT_SCORING_TYPE = 'Master Data Report - 360 - Scoring Type',
  MASTER_DATA_REPORT_360_REPORT_MAPPER = 'Master Data Report - 360 - Report Mapper',
  MASTER_DATA_REPORT_360_REPORT_SUB_CATEGORY = 'Master Data Report - 360 - Sub Category',
  MASTER_DATA_REPORT_360_REPORT_TRAITS = 'Master Data Report - 360 - Traits',
  MASTER_DATA_REPORT_360_REPORT_INTERACTOME = 'Master Data Report - 360 - Interactome',
  MASTER_DATA_REPORT_PRODIGY = 'Master Data Report - Prodigy',
  MASTER_DATA_REPORT_PRODIGY_PANEL = 'Master Data Report - Prodigy - Panel',
  MASTER_DATA_REPORT_PRODIGY_CATEGORY = 'Master Data Report - Prodigy - Category',
  MASTER_DATA_REPORT_PRODIGY_TRAITS = 'Master Data Report - Prodigy - Traits',
  MASTER_DATA_REPORT_PRODIGY_SCORING_TYPE = 'Master Data Report - Prodigy - Scoring Type',
  MASTER_DATA_REPORT_PRODIGY_REPORT_MAPPER = 'Master Data Report - Prodigy - Report Mapper',
  MASTER_DATA_REPORT_PRODIGY_CONTENT_MANAGEMENT = 'Master Data Report - Prodigy - Content Management',
  MASTER_DATA_REPORT_NOURISH = 'Master Data Report - Nourish',
  MASTER_DATA_REPORT_NOURISH_SCORING_TYPE = 'Master Data Report - Nourish - Scoring Type',
  MASTER_DATA_REPORT_NOURISH_REPORT_MAPPER = 'Master Data Report - Nourish - Report Mapper',
  MASTER_DATA_REPORT_NOURISH_PANEL = 'Master Data Report - Nourish - Panel',
  MASTER_DATA_REPORT_NOURISH_CATEGORY = 'Master Data Report - Nourish - Category',
  MASTER_DATA_REPORT_NOURISH_SUBCATEGORY = 'Master Data Report - Nourish - Sub Category',
  MASTER_DATA_REPORT_NOURISH_TRAITS = 'Master Data Report - Nourish - Traits',
  MASTER_DATA_REPORT_SPORTGENOMIC = 'Master Data Report - Sport Genomics',
  MASTER_DATA_REPORT_SPORTGENOMIC_REPORT_MAPPER = 'Master Data Report - Sport Genomics - Report Mapper',
  // endof master data report module

  // startof master dictionary module
  MASTER_DICTIONARY = 'Master Dictionary',
  MASTER_DICTIONARY_CONTENT_MANAGEMENT = 'Master Dictionary - Content Management',
  // endof master dictionary module

  // startof transactions module
  TRANSACTIONS = 'Transactions',
  TRANSACTIONS_ORDER = 'Transactions - Order',
  TRANSACTIONS_HOME_SERVICE_ORDER = 'Transactions - Home Service - Order',
  TRANSACTIONS_HOME_SERVICE_SETTINGS = 'Transactions - Home Service - Settings',
  // endof transactions module

  // startof shop-management module
  SHOP_MANAGEMENT = 'Shop Management',
  SHOP_MANAGEMENT_SHOP_CATALOG = 'Shop Management- Shop Catalog',
  SHOP_MANAGEMENT_PRODUCT_BUNDLING = 'Shop Management - Product Bundling',
  // endof shop-management module

  // startof appointment module
  APPOINTMENT = 'Appointment',
  APPOINTMENT_COUNSELOR = 'Appointment - Counselor',
  APPOINTMENT_COUNSELOR_PROFESSION = 'Appointment - Counselor - Profession',
  APPOINTMENT_COUNSELOR_LIST = 'Appointment - Counselor - List',
  APPOINTMENT_COUNSELOR_PERFORMANCE = 'Appointment - Counselor - Performance',
  APPOINTMENT_APPOINTMENT = 'Appointment - Appointement',
  APPOINTMENT_APPOINTMENT_CONSULTATION = 'Appointment - Appointement - Consultation',
  APPOINTMENT_APPOINTMENT_SCHEDULE = 'Appointment - Appointment -  Schedule',
  APPOINTMENT_APPOINTMENT_FEEDBACK = 'Appointment - Appointment - Feedback',
  APPOINTMENT_APPOINTMENT_BOOKING_SETTING = 'Appointment - Appointment - Booking Setting',
  // endof appointment module

  // startof contents module
  CONTENTS = 'Contents',
  CONTENTS_GLOSSARY = 'Contents - Glossary',
  CONTENTS_ARTICLE = 'Contents - Article',
  CONTENTS_ARTICLE_NEWS = 'Contents - Article - News',
  CONTENTS_ARTICLE_CATEGORY = 'Contents - Article - Category',
  CONTENTS_ARTICLE_SUB_CATEGORY = 'Contents - Article - Sub Category',
  CONTENTS_ARTICLE_ARTICLE = 'Contents - Article - Article',
  CONTENTS_MEDIA = 'Contents - Media',
  CONTENTS_MEDIA_MUSIC = 'Contents - Media - Music',
  CONTENTS_MEDIA_PODCAST_AND_VIDEO = 'Contents - Media - Podcast and Video',
  // endof contents module

  // startof dna-report module
  DNA_REPORT = 'DNA Report',
  DNA_REPORT_UPDATE_REPORT = 'DNA Report - Update Report',
  DNA_REPORT_CATEGORY = 'DNA Report - Category',
  DNA_REPORT_REPORT = 'DNA Report - Report',
  DNA_REPORT_PURCHASED_REPORT = 'DNA Report - Purchased Report',
  DNA_REPORT_PDF_REPORT = 'DNA Report - PDF Report',
  // endof dna-report module

  // startof promotion module
  PROMOTION = 'Promotion',
  PROMOTION_BANNER = 'Promotion - Banner',
  PROMOTION_REFERRAL_LINK = 'Promotion - Referral - Link',
  PROMOTION_REFERRAL_TRACKING = 'Promotion - Referral - Tracking',
  PROMOTION_VOUCHER = 'Promotion - Voucher',
  PROMOTION_VOUCHER_GENERATOR = 'Promotion - Voucher - Generator',
  PROMOTION_VOUCHER_TRACKING = 'Promotion - Voucher - Tracking',
  // endof promotion module

  // startof analytics module
  ANALYTIC = 'Analytic',
  ANALYTIC_SALES_DATA = 'Analytic - Sales Data',
  ANALYTIC_CUSTOMER_DATA = 'Analytic - Customer Data',
  ANALYTIC_TRACKING = 'Analytic - Tracking',
  // endof analytics module

  // startof partner-management
  PARTNER_MANAGEMENT = 'Partner Management',
  PARTNER_MANAGEMENT_ROLE = 'Partner Management - Role',
  PARTNER_MANAGEMENT_PARTNER_LIST = 'Partner Management - Partner List',
  PARTNER_MANAGEMENT_REPORT_MAPPER = 'Partner Management - Report Mapper',
  PARTNER_MANAGEMENT_BANK = 'Partner Management - Bank',
  // endof partner-management

  // startof ehr
  EHR = 'Electronic Health Report',
  EHR_LAB_RESULT_TYPE = 'Electronic Health Report - Lab Result Type',
  EHR_MEDICAL_CHECKUP = 'Electronic Health Report - Medical Check Up',
  // endof ehr

  // startof asax module
  ASAX = 'AsaXperience',
  ASAX_MERCHANT_CATEGORY = 'AsaXperience - Merchant - Category',
  ASAX_MERCHANT_MASTER_DATA = 'AsaXperience - Merchant - Master Data',
  // endof asax module

  AUTH = 'AUTH',
  AUTH_LOGIN = 'AUTH_LOGIN'
}

export interface Module {
  name: string;
  show: boolean;
  href?: string;
  icon?: {
    type: 'mdi' | 'path';
    src: string;
  };
  pages?: Array<{
    name: string;
    href: string;
    roles?: Array<'EDITOR'>;
  }>;
  modules?: Partial<Record<MODULE, Module>>;
  components?: Record<string, any>;
}

export default <Partial<Record<MODULE, Module>>>{
  [MODULE.DASHBOARD]: {
    href: '/dashboard',
    name: 'Dashboard',
    show: true,
    icon: {
      type: 'path',
      src: '/menu/home.svg'
    },
    pages: [{ name: 'Verification', href: '/verification', roles: ['EDITOR'] }],
    components: {
      button_process_report: true,
      analytics_latest: true,
      analytics_filter: false
    }
  },
  [MODULE.PRODUCT]: {
    name: 'Product',
    show: true,
    modules: {
      [MODULE.PRODUCT_BATCH_NUMBER]: {
        href: '/kit/batch',
        name: 'Batch Number Generator',
        show: true
      },
      [MODULE.PRODUCT_KIT_CODE]: {
        href: '/kit',
        name: 'Kit Code',
        show: true,
        components: {
          button_deactivate: true,
          button_picture: true,
          field_owner: true,
          fetch_partnership: true,
          filter_owner: true,
          filter_generated_date: true
        }
      },
      [MODULE.PRODUCT_RESWAB]: {
        href: '/reswab',
        name: 'Reswab',
        show: true
      }
    }
  },
  [MODULE.TRANSACTIONS]: {
    name: 'Transactions',
    show: true,
    modules: {
      [MODULE.TRANSACTIONS_ORDER]: {
        name: 'Order',
        href: '/order',
        show: true
      },
      [MODULE.TRANSACTIONS_HOME_SERVICE_ORDER]: {
        name: 'Home Service Order',
        show: true,
        href: '/home-service'
      },
      [MODULE.TRANSACTIONS_HOME_SERVICE_SETTINGS]: {
        name: 'Home Service Setting',
        href: '/home-service/settings',
        show: true
      }
    }
  },
  [MODULE.PARTNER_MANAGEMENT]: {
    name: 'Partner Management',
    show: true,
    modules: {
      [MODULE.PARTNER_MANAGEMENT_ROLE]: {
        name: 'Role',
        show: true,
        href: '/partner/role',
        pages: [
          {
            name: 'Add Partner Role',
            href: '/partner/role/add',
            roles: ['EDITOR']
          }
        ]
      },
      [MODULE.PARTNER_MANAGEMENT_BANK]: {
        name: 'Bank',
        show: true,
        href: '/partner/bank'
      },
      [MODULE.PARTNER_MANAGEMENT_PARTNER_LIST]: {
        name: 'Partner List',
        show: true,
        href: '/partner',
        pages: [
          { name: 'Add', href: '/partner/add', roles: ['EDITOR'] },
          { name: 'View', href: '/partner/view' },
          { name: 'Order', href: '/partner/order' }
        ]
      },
      [MODULE.PARTNER_MANAGEMENT_REPORT_MAPPER]: {
        name: 'Report Mapper',
        show: true,
        href: '/partnership/report/category'
      }
    }
  },
  [MODULE.MASTER_DICTIONARY]: {
    name: 'Master Dictionary',
    show: true,
    modules: {
      [MODULE.MASTER_DICTIONARY_CONTENT_MANAGEMENT]: {
        name: 'Content Management',
        show: true,
        href: '/master/dictionary/content',
        pages: [
          { name: 'View', href: '/master/dictionary/content/_id' },
          {
            name: 'Edit',
            href: '/master/dictionary/content/_id/edit',
            roles: ['EDITOR']
          }
        ]
      }
    }
  },
  [MODULE.MASTER_DATA_REPORT]: {
    name: 'Master Data Report',
    show: true,
    showSKUComponent: true,
    modules: {
      [MODULE.MASTER_DATA_REPORT_PRODUCT_TYPE]: {
        name: 'Report Product Type',
        show: true,
        href: '/master/data/product-type'
      },
      // [MODULE.MASTER_DATA_REPORT_REPORT]: {
      //   name: 'Reports',
      //   show: true,
      //   modules: {
      //     [MODULE.MASTER_DATA_REPORT_REPORT_MAPPER]: {
      //       name: 'Report Mapper',
      //       show: true,
      //       href: '/master/data/report/mapper'
      //     },
      //     [MODULE.MASTER_DATA_REPORT_REPORT_CATEGORY]: {
      //       name: 'Report Category',
      //       show: true,
      //       href: '/master/data/report/category'
      //     },
      //     [MODULE.MASTER_DATA_REPORT_REPORT_TRAITS]: {
      //       name: 'Traits',
      //       show: true,
      //       href: '/master/data/report/traits'
      //     }
      //   }
      // },
      [MODULE.MASTER_DATA_REPORT_360]: {
        name: '360 DNA',
        show: true,
        modules: {
          [MODULE.MASTER_DATA_REPORT_360_REPORT_SCORING_TYPE]: {
            name: 'Scoring Type',
            href: '/360/score',
            show: true,
            components: {
              field_response_ID: true
            },
            pages: [
              { name: 'View', href: '/360/score/view' },
              { name: 'Add', href: '/360/score/add', roles: ['EDITOR'] },
              { name: 'Edit', href: '/360/score/edit', roles: ['EDITOR'] }
            ]
          },
          [MODULE.MASTER_DATA_REPORT_360_REPORT_MAPPER]: {
            name: 'Report Mapper',
            href: '/360/mapper',
            show: true
          },
          [MODULE.MASTER_DATA_REPORT_360_REPORT_PANEL]: {
            name: 'Panel',
            href: '/360/panel',
            show: true,
            pages: [
              { name: 'View', href: '/360/panel/view' },
              { name: 'Guideline', href: '/360/panel/guideline' },
              { name: 'Add', href: '/360/panel/add', roles: ['EDITOR'] },
              { name: 'Edit', href: '/360/panel/edit', roles: ['EDITOR'] }
            ]
          },
          [MODULE.MASTER_DATA_REPORT_360_REPORT_CATEGORY]: {
            name: 'Category',
            show: true,
            href: '/360/category',
            pages: [
              { name: 'View', href: '/360/category/view' },
              { name: 'Guideline', href: '/360/category/guideline' },
              { name: 'Add', href: '/360/category/add', roles: ['EDITOR'] },
              { name: 'Edit', href: '/360/category/edit', roles: ['EDITOR'] }
            ]
          },
          [MODULE.MASTER_DATA_REPORT_360_REPORT_SUB_CATEGORY]: {
            name: 'Sub Category',
            show: true,
            href: '/360/sub-category',
            pages: [
              { name: 'View', href: '/360/sub-category/view' },
              { name: 'Add', href: '/360/sub-category/add', roles: ['EDITOR'] },
              {
                name: 'Edit',
                href: '/360/sub-category/edit',
                roles: ['EDITOR']
              }
            ]
          },
          [MODULE.MASTER_DATA_REPORT_360_REPORT_TRAITS]: {
            name: 'Traits',
            show: true,
            href: '/360/traits',
            components: {
              field_trait_description: false
            },
            pages: [
              { name: 'View', href: '/360/traits/view' },
              { name: 'Add', href: '/360/traits/add', roles: ['EDITOR'] },
              { name: 'Edit', href: '/360/traits/edit', roles: ['EDITOR'] }
            ]
          },
          [MODULE.MASTER_DATA_REPORT_360_REPORT_INTERACTOME]: {
            name: 'Interactome',
            show: true,
            href: '/360/interactome',
            pages: [
              { name: 'View', href: '/360/interactome/view' },
              { name: 'Add', href: '/360/interactome/add', roles: ['EDITOR'] },
              { name: 'Edit', href: '/360/interactome/edit', roles: ['EDITOR'] }
            ]
          }
        }
      },
      [MODULE.MASTER_DATA_REPORT_PRODIGY]: {
        name: 'Prodigy Report',
        show: true,
        modules: {
          [MODULE.MASTER_DATA_REPORT_PRODIGY_PANEL]: {
            name: 'Panel',
            show: true,
            href: '/prodigy/panel',
            pages: [
              { name: 'View', href: '/prodigy/panel/view' },
              { name: 'Guideline', href: '/prodigy/panel/guideline' },
              { name: 'Edit', href: '/prodigy/panel/edit', roles: ['EDITOR'] }
            ]
          },
          [MODULE.MASTER_DATA_REPORT_PRODIGY_CATEGORY]: {
            name: 'Category',
            show: true,
            href: '/prodigy/category',
            pages: [
              { name: 'View', href: '/prodigy/category/view' },
              { name: 'Guideline', href: '/prodigy/category/guideline' },
              { name: 'Add', href: '/prodigy/category/add', roles: ['EDITOR'] },
              {
                name: 'Edit',
                href: '/prodigy/category/edit',
                roles: ['EDITOR']
              }
            ]
          },
          [MODULE.MASTER_DATA_REPORT_PRODIGY_TRAITS]: {
            name: 'Traits',
            show: true,
            href: '/prodigy/traits',
            pages: [
              { name: 'View', href: '/prodigy/traits/view' },
              { name: 'Guideline', href: '/prodigy/traits/guideline' },
              { name: 'Add', href: '/prodigy/traits/add' },
              { name: 'Edit', href: '/prodigy/traits/edit' }
            ]
          },
          [MODULE.MASTER_DATA_REPORT_PRODIGY_SCORING_TYPE]: {
            name: 'Scoring Type',
            show: true,
            href: '/prodigy/score',
            pages: [
              { name: 'View', href: '/prodigy/score/view' },
              { name: 'Guideline', href: '/prodigy/score/guideline' },
              { name: 'Add', href: '/prodigy/score/add', roles: ['EDITOR'] },
              { name: 'Edit', href: '/prodigy/score/edit', roles: ['EDITOR'] }
            ]
          },
          [MODULE.MASTER_DATA_REPORT_PRODIGY_REPORT_MAPPER]: {
            name: 'Report Mapper',
            show: true,
            href: '/prodigy/mapper',
            pages: [{ name: 'Guideline', href: '/prodigy/mapper/guideline' }]
          },
          [MODULE.MASTER_DATA_REPORT_PRODIGY_CONTENT_MANAGEMENT]: {
            name: 'Content Management',
            show: true,
            href: '/prodigy/localization',
            pages: [
              { name: 'View', href: '/prodigy/localization/view' },
              { name: 'Guideline', href: '/prodigy/localization/guideline' },
              {
                name: 'Edit',
                href: '/prodigy/localization/edit',
                roles: ['EDITOR']
              }
            ]
          }
        }
      },
      [MODULE.MASTER_DATA_REPORT_NOURISH]: {
        name: 'Nourish',
        show: true,
        modules: {
          [MODULE.MASTER_DATA_REPORT_NOURISH_REPORT_MAPPER]: {
            name: 'Report Mapper',
            show: true,
            href: '/nutrigenetic/report-mapper'
          }
        }
      },
      [MODULE.MASTER_DATA_REPORT_SPORTGENOMIC]: {
        name: 'Sport Genomics',
        show: true,
        modules: {
          [MODULE.MASTER_DATA_REPORT_SPORTGENOMIC_REPORT_MAPPER]: {
            name: 'Report Mapper',
            show: true,
            href: '/sportgenomic/report-mapper'
          }
        }
      }
    }
  },
  [MODULE.DNA_REPORT]: {
    name: 'DNA report',
    show: true,
    modules: {
      [MODULE.DNA_REPORT_UPDATE_REPORT]: {
        name: 'Update Report',
        show: true,
        href: '/dna-report/update'
      },
      [MODULE.DNA_REPORT_CATEGORY]: {
        name: 'Category',
        show: true,
        href: '/product/category'
      },
      [MODULE.DNA_REPORT_REPORT]: {
        name: 'Report',
        show: true,
        href: '/product/report'
      },
      [MODULE.DNA_REPORT_PURCHASED_REPORT]: {
        name: 'Purchased Report',
        show: true,
        href: '/product/purchase'
      },
      [MODULE.DNA_REPORT_PDF_REPORT]: {
        name: 'PDF Report',
        show: false,
        href: '/product/report'
      }
    }
  },
  [MODULE.EHR]: {
    name: 'Electronic Health Report',
    show: true,
    modules: {
      [MODULE.EHR_LAB_RESULT_TYPE]: {
        name: 'Lab Result Type',
        show: true,
        href: '/ehr/lab/result-type'
      },
      [MODULE.EHR_MEDICAL_CHECKUP]: {
        name: 'Medical Check Up',
        show: true,
        href: '/ehr/mcu',
        pages: [
          { name: 'Add', href: '/ehr/mcu/add' },
          { name: 'Patient', href: '/ehr/mcu/patient' }
        ]
      }
    }
  },
  [MODULE.SHOP_MANAGEMENT]: {
    name: 'Shop Management',
    show: true,
    modules: {
      [MODULE.SHOP_MANAGEMENT_SHOP_CATALOG]: {
        name: 'Shop Catalog',
        show: true,
        href: '/product/type',
        pages: [
          { name: 'Add', href: '/product/type/add', roles: ['EDITOR'] },
          { name: 'Edit', href: '/product/type/edit', roles: ['EDITOR'] }
        ],
        components: {
          pageAdd: {
            show: true,
            field_product_code: true,
            field_short_description: true,
            field_product_image_mobile_only: false,
            field_disable_buynow: true
          }
        }
      },
      [MODULE.SHOP_MANAGEMENT_PRODUCT_BUNDLING]: {
        name: 'Product Bundling',
        show: true,
        href: '/promotion/bundling',
        pages: [
          { name: 'Add', href: '/promotion/bundling/add' },
          { name: 'Edit', href: '/promotion/bundling/edit' }
        ],
        components: {
          field_product_image_mobile_only: false
        }
      }
    }
  },
  [MODULE.ASAX]: {
    name: 'AsaXperience',
    show: true,
    modules: {
      [MODULE.ASAX_MERCHANT_CATEGORY]: {
        name: 'Merchant Category',
        show: true,
        href: '/asaxperience/merchant/category'
      },
      [MODULE.ASAX_MERCHANT_MASTER_DATA]: {
        name: 'Master Data Merchant',
        show: true,
        href: '/asaxperience/merchant'
      }
    }
  },
  [MODULE.APPOINTMENT]: {
    name: 'Appointment',
    show: true,
    modules: {
      [MODULE.APPOINTMENT_COUNSELOR]: {
        name: 'Counselor',
        show: true,
        modules: {
          [MODULE.APPOINTMENT_COUNSELOR_PROFESSION]: {
            name: 'Profession',
            show: true,
            href: '/appointment/counselor/profession'
          },
          [MODULE.APPOINTMENT_COUNSELOR_LIST]: {
            name: 'Counselor List',
            show: true,
            href: '/appointment/counselor',
            pages: [
              {
                name: 'Add',
                href: '/appointment/counselor/add',
                roles: ['EDITOR']
              },
              {
                name: 'Edit',
                href: '/appointment/counselor/edit',
                roles: ['EDITOR']
              },
              { name: 'View', href: '/appointment/counselor/view' }
            ]
          },
          [MODULE.APPOINTMENT_COUNSELOR_PERFORMANCE]: {
            name: 'Performance',
            show: true,
            href: '/appointment/counselor/performance'
          }
        }
      },
      [MODULE.APPOINTMENT_APPOINTMENT]: {
        name: 'Appointment',
        show: true,
        modules: {
          [MODULE.APPOINTMENT_APPOINTMENT_CONSULTATION]: {
            name: 'Consultation',
            show: true,
            href: '/appointment/consultation',
            components: {
              open_invoice: true,
              invoice_information_section: true,
              status_history_section: true
            }
          },
          [MODULE.APPOINTMENT_APPOINTMENT_SCHEDULE]: {
            name: 'Schedule',
            show: true,
            href: '/appointment'
          },
          [MODULE.APPOINTMENT_APPOINTMENT_FEEDBACK]: {
            name: 'Feedback',
            show: true,
            href: '/appointment/feedback'
          },
          [MODULE.APPOINTMENT_APPOINTMENT_BOOKING_SETTING]: {
            name: 'Booking Setting',
            show: true,
            href: '/appointment/setting'
          }
        }
      }
    }
  },
  [MODULE.ANALYTIC]: {
    name: 'Analytics',
    show: true,
    modules: {
      [MODULE.ANALYTIC_SALES_DATA]: {
        name: 'Sales Data',
        show: true,
        href: '/sales'
      },
      [MODULE.ANALYTIC_CUSTOMER_DATA]: {
        name: 'Customer Data',
        show: true,
        href: '/customer'
      },
      [MODULE.ANALYTIC_TRACKING]: {
        name: 'Analytics Tracking',
        show: true,
        href: '/analytic/tracking'
      }
    }
  },
  [MODULE.CONTENTS]: {
    name: 'Contents',
    show: true,
    modules: {
      [MODULE.CONTENTS_ARTICLE]: {
        name: 'Articles',
        show: true,
        modules: {
          [MODULE.CONTENTS_ARTICLE_CATEGORY]: {
            name: 'Category',
            show: true,
            href: '/article/category'
          },
          [MODULE.CONTENTS_ARTICLE_SUB_CATEGORY]: {
            name: 'Sub Category',
            show: true,
            href: '/article/sub-category'
          },
          [MODULE.CONTENTS_ARTICLE_ARTICLE]: {
            name: 'Article',
            show: true,
            href: '/article',
            pages: [
              { name: 'Add', href: '/article/add', roles: ['EDITOR'] },
              { name: 'Edit', href: '/article/edit', roles: ['EDITOR'] }
            ],
            components: {
              tabs: true,
              header_viewers_count: true,
              button_archive: true
            }
          },
          [MODULE.CONTENTS_ARTICLE_NEWS]: {
            name: 'News',
            show: true,
            href: '/news',
            pages: [
              { name: 'Add', href: '/news/add', roles: ['EDITOR'] },
              { name: 'Edit', href: '/news/edit', roles: ['EDITOR'] }
            ]
          }
        }
      },
      [MODULE.CONTENTS_MEDIA]: {
        name: 'Media',
        show: true,
        modules: {
          [MODULE.CONTENTS_MEDIA_MUSIC]: {
            name: 'Music',
            show: true,
            href: '/media/music',
            pages: [
              { name: 'Add', href: '/media/music/add', roles: ['EDITOR'] },
              { name: 'Edit', href: '/media/music/edit', roles: ['EDITOR'] }
            ]
          },
          [MODULE.CONTENTS_MEDIA_PODCAST_AND_VIDEO]: {
            name: 'Podcast & Video',
            show: true,
            href: '/media/podcast',
            pages: [
              { name: 'Add', href: '/media/podcast/add', roles: ['EDITOR'] },
              { name: 'Edit', href: '/media/podcast/edit', roles: ['EDITOR'] }
            ]
          }
        }
      },
      [MODULE.CONTENTS_GLOSSARY]: {
        name: 'Glossary',
        show: true,
        href: '/glossary'
      }
    }
  },
  [MODULE.PROMOTION]: {
    name: 'Promotions',
    show: true,
    modules: {
      [MODULE.PROMOTION_BANNER]: {
        name: 'Banner',
        show: true,
        href: '/promotion/banner',
        pages: [
          { name: 'Add', href: '/promotion/banner/add', roles: ['EDITOR'] },
          { name: 'View', href: '/promotion/banner/view' }
        ]
      },
      [MODULE.PROMOTION_REFERRAL_LINK]: {
        name: 'Referral Link',
        show: true,
        href: '/promotion'
      },
      [MODULE.PROMOTION_REFERRAL_TRACKING]: {
        name: 'Referrals Tracking',
        show: true,
        href: '/promotion/referral'
      },
      [MODULE.PROMOTION_VOUCHER]: {
        name: 'Voucher',
        show: true,
        modules: {
          [MODULE.PROMOTION_VOUCHER_GENERATOR]: {
            name: 'Voucher Generator',
            show: true,
            href: '/promotion/voucher'
          },
          [MODULE.PROMOTION_VOUCHER_TRACKING]: {
            name: 'Voucher Tracking',
            show: true,
            href: '/promotion/voucher/tracking'
          }
        }
      }
    }
  },
  [MODULE.SETTINGS]: {
    name: 'Settings',
    show: true,
    modules: {
      [MODULE.SETTINGS_USER_MANAGEMENT]: {
        href: '/user',
        name: 'User Management',
        show: true
      },
      [MODULE.SETTINGS_SETTINGS]: {
        href: '/setting',
        name: 'Settings',
        show: true
      }
    }
  },
  [MODULE.AUTH]: {
    name: 'Auth',
    show: false,
    modules: {
      [MODULE.AUTH_LOGIN]: {
        show: false,
        name: 'Auth Login',
        components: {
          whitelabel: false
        }
      }
    }
  }
};
