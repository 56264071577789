import { cloneDeep, filter, find, get, sortBy } from 'lodash';
import { Component, Vue } from 'nuxt-property-decorator';

@Component({})
export default class MasterDataProductTypeNav extends Vue {
  items = [];
  loading = false;

  get token(): string {
    return this.$auth.getToken('local');
  }

  get reportUrl() {
    return process.env.REPORT_URL;
  }

  get state() {
    return this.$store.state?.sku;
  }

  // get filteredItems() {
  //   forEach(this.items, (item) => {
  //     item.
  //   })
  // }

  sortedSubMenus(subMenus: any[]) {
    return sortBy(subMenus, (subMenu) => subMenu.position);
  }

  isActive(sku: any, page: any): boolean {
    let pageName;
    switch (page.code) {
      case 'scoring_type':
        pageName = 'sku-score';
        break;
      case 'report_mapper':
        pageName = 'sku-mapper';
        break;
      case 'panel':
        pageName = 'sku-panel';
        break;
      case 'category':
        pageName = 'sku-category';
        break;
      case 'sub_category':
        pageName = 'sku-sub-category';
        break;
      case 'traits':
        pageName = 'sku-traits';
        break;
      case 'interactome':
        pageName = 'sku-interactome';
        break;

      default:
        break;
    }
    const skuActive = sku.id === this.$route.query.id;
    const pageActive = pageName === this.$route.name;
    return skuActive && pageActive;
  }

  goTo(sku: any, page: any) {
    // console.log(55, this.$route.query);
    // console.log(66, this.$route.path);
    this.$store.commit('sku/changeSKU', sku);
    this.$store.commit('sku/changePage', page);
    // console.log(77, this.state);

    // const skuActive = this.state.sku.id ===

    let route: any;
    switch (page.code) {
      case 'scoring_type':
        route = 'score';
        break;
      case 'report_mapper':
        route = 'mapper';
        break;
      case 'panel':
        route = 'panel';
        break;
      case 'category':
        route = 'category';
        break;
      case 'sub_category':
        route = 'sub-category';
        break;
      case 'traits':
        route = 'traits';
        break;
      case 'interactome':
        route = 'interactome';
        break;
      case 'exercise_database':
        route = 'exercise';
        break;

      default:
        break;
    }
    this.$router.push({ path: `/sku/${route}`, query: { id: sku.id } });
  }

  async fetchData(): Promise<void> {
    try {
      this.loading = true;

      const url = 'report-mapper/report-type?include=report-type-setting';
      const { data } = await this.$axios({
        url: `${this.reportUrl}/${url}`,
        method: 'GET',
        params: {
          withCount: true,
          include: 'report-type-sub-menu'
        },
        headers: {
          Authorization: this.token
        }
      });
      if (data) {
        // sortBy(subMenus, (subMenu) => subMenu.position);
        const items = sortBy(
          cloneDeep(get(data, 'data.results')),
          (item) => item.position
        );

        this.items = filter(items, (item) => {
          const setting = find(
            item.reportTypeSetting,
            (setting) => setting.key === 'isExcludeDisplayingOnAdminDashboard'
          );
          if (setting) {
            return JSON.parse(setting.val) === false;
          }
          return true;
        });
      }

      this.loading = false;
    } catch (error) {
      this.$emitter.emit('toast', error);
    }
  }

  async init() {
    await this.fetchData();
  }

  mounted() {
    this.init();
  }
}
